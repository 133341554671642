import React from 'react';
import CardItem from '../../CardItem';

export default function RadioJulyCard() {
  return (
    <CardItem
      title="Radio July"
      subtitle="Bombes de peinture"
      description="2022"
      mediaFile={require("../../../media/graffitis/radio-july.jpg")}
    />
  )
}
