import React from 'react';
import CardItem from '../../CardItem';

export default function DistopyCard() {
  return (
    <CardItem
      title="Paris distopie"
      subtitle="Acrylique sur porte"
      description="2015"
      mediaFile={require("../../../media/landscapes/distopy.jpg")}
    />
  )
}
