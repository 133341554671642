import React from 'react';
import CardItem from '../../CardItem';

export default function GayLussacCard() {
  return (
    <CardItem
      title="Louis Joseph Gay-Lussac (concours)"
      subtitle="Aquarelle sur papier Canson"
      description="2010"
      mediaFile={require("../../../media/portraits/gay-lussac.jpg")}
    />
  )
}
