import React from 'react';
import CardItem from '../../CardItem';

export default function DavitruveCard() {
  return (
    <CardItem
      title="Davitruve"
      subtitle="Stylo noir sur papier Canson"
      description="2020"
      mediaFile={require("../../../media/portraits/davitruve.jpg")}
    />
  )
}
