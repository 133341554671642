import React from 'react';
import CardItem from '../../CardItem';

export default function EguzonCard() {
  return (
    <CardItem
      title="Lac d'Eguzon"
      subtitle="Acrylique sur toile"
      description="2012"
      mediaFile={require("../../../media/landscapes/eguzon.jpg")}
    />
  )
}
