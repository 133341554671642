import React from 'react';
import CardItem from '../../CardItem';

export default function NafelsCard() {
  return (
    <CardItem
      title="Montagne à Nafels"
      subtitle="Acrylique sur toile"
      description="2011"
      mediaFile={require("../../../media/landscapes/nafels.jpg")}
    />
  )
}
