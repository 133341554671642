import React from 'react';
import CardItem from '../../CardItem';

export default function RadioJuly2Card() {
  return (
    <CardItem
      title="Radio July 2"
      subtitle="Bombes de peinture"
      description="2023"
      mediaFile={require("../../../media/graffitis/radio-july-2.jpg")}
    />
  )
}
