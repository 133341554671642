import React from 'react';
import CardItem from '../../CardItem';

export default function EnvulminuresCard() {
  return (
    <CardItem
      title="Envulminures"
      subtitle="Feutre sur papier"
      description="2023"
      mediaFile={require("../../../media/landscapes/envulminures.jpg")}
    />
  )
}
