import React from 'react';
import CardItem from '../../CardItem';

export default function ElephantsAndTreeCard() {
  return (
    <CardItem
      title="Eléphants et arbre"
      subtitle="Acrylique sur toile"
      description="2010"
      mediaFile={require("../../../media/landscapes/elephants-and-tree.jpg")}
    />
  )
}
