import React from 'react';
import CardItem from '../../CardItem';

export default function BedroomGraffitiCard() {
  return (
    <CardItem
      title="Premier graffiti"
      subtitle="Peinture industrielle"
      description="2000"
      mediaFile={require("../../../media/youth/bedroom-graffiti.jpg")}
    />
  )
}
